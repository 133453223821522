import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Container = styled.div`
  background: white;
  position: absolute;
  top: 100%;
  transform: translateY(
    calc(-${selectTheme('spacingY.md')} + -${selectTheme('spacingY.23xl')})
  );

  margin: 0 20px;
  padding: ${selectTheme('spacingY.6xl')};

  box-shadow: ${selectTheme('shadow.lg')};

  ${media.up('3xl')} {
    margin: 0 ${selectTheme('spacingX.sm')};
  }

  ${media.up('4xl')} {
    top: auto;
    bottom: 0;
    transform: translateY(-64px);
    margin: 0;
    max-width: 392px;
    padding: ${selectTheme('spacingY.6xl')};
  }
`

export const Title = styled(RichText)`
  margin-bottom: ${selectTheme('spacingBottom.3xl')};
`

export const Description = styled(RichText)``
