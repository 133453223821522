import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { LazyImage } from 'components/LazyImage'

const containerStyles = ({ theme }) => ({
  top: css`
    ${media.down('3xl')} {
      padding-bottom: calc(
        ${selectTheme('spacingBottom.21xl')({ theme })} +
          ${selectTheme('spacingBottom.8xl')({ theme })}
      );
    }
  `,
  bottom: css`
    ${media.down('3xl')} {
      padding-bottom: calc(
        ${selectTheme('spacingBottom.21xl')({ theme })} +
          ${selectTheme('spacingBottom.8xl')({ theme })}
      );
    }
  `,
})

export const Container = styled.div(
  ({ imageVariant, theme }) => css`
    width: 100%;
    grid-area: image;
    position: relative;
    height: 100%;

    ${containerStyles({ theme })[imageVariant || 'bottom']}
  `,
)

export const Image = styled(LazyImage)`
  height: 360px;

  ${media.up('3xl')} {
    height: 330px;
    box-shadow: ${selectTheme('shadow.lg')};
  }

  ${media.up('4xl')} {
    height: 600px;
    width: 100%;

    box-shadow: ${selectTheme('shadow.lg')};
  }
`
