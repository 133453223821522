import { bool, func, object } from 'prop-types'

import HubspotForm from 'components/HubspotForm/HubspotForm'

import { StyledModal as Modal } from './styles'

const propTypes = {
  form: object,
  onClose: func,
  visible: bool,
}

const FormModal = ({ form, ...others }) => {
  if (!form || !form?.hubspotFormId) return null
  return (
    <Modal {...others}>
      <HubspotForm
        {...form}
        formWithWebCardWrapper={false}
        dispatchEvents
        delayRedirect
        showDescription
        isGreenStone
      />
    </Modal>
  )
}

FormModal.propTypes = propTypes
export default FormModal
