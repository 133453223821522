import { array, bool, func, object, oneOf, string } from 'prop-types'

import FormModal from 'components/FormModal'

import ButtonCta from '../components/ButtonCta'
import Description from '../components/Description'
import ImageContainer from '../components/ImageContainer'
import InfoContainer from '../components/InfoContainer'
import Title from '../components/Title'
import Wrapper from '../components/Wrapper'

const propTypes = {
  artDirection: array,
  buttonCta: object,
  description: string,
  form: object,
  imageCard: object,
  imageVariant: oneOf(['top', 'bottom']),
  setShowModal: func,
  showModal: bool,
  title: string,
  titleAs: string,
  verticalAlignment: oneOf(['top', 'bottom']),
}

const defaultProps = {
  imageVariant: 'bottom',
  verticalAlignment: 'top',
}

const Template01 = ({
  artDirection,
  buttonCta,
  description,
  form,
  imageCard,
  imageVariant,
  setShowModal,
  showModal,
  title,
  titleAs,
  verticalAlignment,
  ...others
}) => {
  return (
    <Wrapper
      verticalAlignment={verticalAlignment}
      noPaddingOnMobile
      {...others}
    >
      <ImageContainer
        imageVariant={imageVariant}
        title={imageCard?.title}
        description={imageCard?.description}
        artDirection={artDirection}
      />
      <InfoContainer paddingOnMobile>
        <Title
          variant={{ _: 'headingMdLight', '5xl': 'headingXlLight' }}
          color="neutral.90"
          as={titleAs}
        >
          {title}
        </Title>
        <Description
          variant={{ _: 'bodyMdRegular', '5xl': 'bodyLgRegular' }}
          color="neutral.80"
        >
          {description}
        </Description>

        <ButtonCta
          onClick={() => setShowModal(true)}
          label={buttonCta?.label}
        />
      </InfoContainer>
      <FormModal
        form={form}
        visible={showModal}
        onClose={() => setShowModal(false)}
      />
    </Wrapper>
  )
}

Template01.propTypes = propTypes
Template01.defaultProps = defaultProps

export default Template01
