import { useEffect } from 'react'
import { bool, func, node } from 'prop-types'

import { trapUserListener } from 'utils/trapUserListener'

import Backdrop from '../Backdrop'

import { animationVariants, Container } from './styles'

const propTypes = {
  children: node,
  handleClose: func,
  isMobile: bool,
}

const preventPropagation = e => {
  e.stopPropagation()
}

const ContentWrapper = ({ children, handleClose, isMobile, ...others }) => {
  const handleDrag = (e, info) => {
    preventPropagation(e)
    if (info.offset.y > 80) handleClose()
  }

  useEffect(() => {
    trapUserListener('modal', handleClose)
  }, [handleClose])

  return (
    <Backdrop onClick={handleClose} {...others}>
      <Container
        id="modal"
        onTap={preventPropagation}
        onPan={preventPropagation}
        onClick={preventPropagation}
        onDrag={handleDrag}
        custom={isMobile}
        variants={animationVariants}
      >
        {children}
      </Container>
    </Backdrop>
  )
}

ContentWrapper.propTypes = propTypes
export default ContentWrapper
