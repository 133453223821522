import { string } from 'prop-types'

import { Typography } from './styles'

const propTypes = {
  children: string,
  color: string,
}

const defaultProps = {
  color: 'neutral.90',
}

const Title = ({ children, ...others }) => {
  return (
    <Typography
      variant={{ _: 'headingMdLight', '5xl': 'headingXlLight' }}
      {...others}
    >
      {children}
    </Typography>
  )
}

Title.propTypes = propTypes
Title.defaultProps = defaultProps

export default Title
