import { styled } from '@creditas-ui/system'
import { getTypography, media, selectTheme } from '@creditas-ui/utilities'

import Modal from 'components/Modal'

export const StyledModal = styled(Modal)`
  .formTitle {
    ${getTypography('headingSmLight')}
    margin-bottom: ${selectTheme('spacingBottom.xl')};
  }

  .formDescription {
    ${getTypography('bodyMdRegular')}
    margin-bottom: ${selectTheme('spacingBottom.10xl')};
  }

  ${media.up('3xl')} {
    .formTitle {
      ${getTypography('headingLgLight')}
    }
  }
`
