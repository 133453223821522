import { useState } from 'react'
import { object, oneOf, string } from 'prop-types'

import { renderTemplate } from 'utils/renderTemplate'

import Template01 from './Template01'
import { setArtDirection } from './utils/setArtDirection'

const propTypes = {
  buttonCtaLabel: string,
  description: string,
  form: object,
  imageDesktop: object,
  imageMobile: object,
  title: string,
  titleHeadingTag: string,
  verticalAlignment: oneOf(['top', 'bottom']),
}

const defaultProps = {
  verticalAlignment: 'top',
}

const ELEMENT_VARIANTS = {
  Template01,
}

const HeroInfluencer = ({
  buttonCtaLabel,
  imageDesktop,
  imageMobile,
  titleHeadingTag,
  verticalAlignment: verticalAlignmentProp,
  ...others
}) => {
  const { artDirection } = setArtDirection(imageDesktop, imageMobile)
  const [showModal, setShowModal] = useState(false)
  const titleAs = titleHeadingTag || 'h2'
  const verticalAlignment =
    verticalAlignmentProp ?? defaultProps.verticalAlignment

  const buttonCta = {
    label: buttonCtaLabel,
    type: 'openModal',
  }
  return renderTemplate(
    {
      artDirection,
      imageVariant: verticalAlignment,
      titleAs,
      showModal,
      setShowModal,
      verticalAlignment,
      buttonCta,
      ...others,
    },
    ELEMENT_VARIANTS,
  )
}

HeroInfluencer.propTypes = propTypes
HeroInfluencer.defaultProps = defaultProps

export default HeroInfluencer
