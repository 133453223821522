import { object, oneOf, string } from 'prop-types'

import Card from './components/Card'

import { Container, Image } from './styles'

const propTypes = {
  artDirection: object,
  description: string,
  imageVariant: oneOf(['top', 'bottom']),
  title: string,
}

const ImageContainer = ({ artDirection, description, imageVariant, title }) => {
  if (!artDirection) return null
  return (
    <Container imageVariant={imageVariant}>
      <Image artDirection={artDirection} />
      <Card description={description} title={title} />
    </Container>
  )
}

ImageContainer.propTypes = propTypes
export default ImageContainer
