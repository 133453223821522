import { useCallback, useEffect, useRef, useState } from 'react'

import { standard } from '@creditas-ui/themes'

const { breakpoints } = standard

export function useResizeHandler(breakpoint = '5xl') {
  const breakpointConstraint = Number(breakpoints[breakpoint].replace('px', ''))
  const [isMobile, setIsMobile] = useState(true)
  const observer = useRef()

  // Method to check if viewport is less than breakpointConstraint
  const handleResize = useCallback(
    viewportWidth => {
      const checkIfMobile = viewportWidth < breakpointConstraint
      // if so, it considers that user is on a mobile device
      setIsMobile(checkIfMobile)
    },
    [breakpointConstraint],
  )

  useEffect(() => {
    // Check if there's any observed element
    if (observer.current) {
      observer.current?.disconnect()
    }

    // Re-create observer to get updated elements
    observer.current = new ResizeObserver(entries => {
      const viewportBox = entries[0]
      if (viewportBox) {
        handleResize(viewportBox.contentRect.width)
      }
    })

    const { current: currentObserver } = observer

    // Observe viewport resizing
    currentObserver.observe(document.querySelector('body'))

    return () => currentObserver?.disconnect()
  }, [handleResize])

  return {
    isMobile,
  }
}
