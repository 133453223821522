import { func, node } from 'prop-types'

import { animationVariants, Container } from './styles'

const propTypes = {
  children: node,
  onClick: func,
}

const Backdrop = ({ children, onClick, ...others }) => {
  return (
    <Container
      className="backdrop"
      onClick={onClick}
      variants={animationVariants}
      initial="hidden"
      animate="visible"
      exit="exit"
      {...others}
    >
      {children}
    </Container>
  )
}

Backdrop.propTypes = propTypes
export default Backdrop
