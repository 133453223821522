// possible focusable elements tags
const focusableElements =
  'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'

const ESC_CODE = 'Escape'
const TAB_CODE = 'Tab'

export function trapUserListener(rootContainerId, escKeyCb = () => {}) {
  if (!rootContainerId) return

  const container = document.querySelector(`#${rootContainerId}`)
  const focusableContent = container.querySelectorAll(focusableElements)

  // get first element to be focused inside container
  const firstFocusableElement = container.querySelectorAll(focusableElements)[0]
  // get last element to be focused inside container
  const lastFocusableElement = focusableContent[focusableContent.length - 1]

  document.addEventListener('keydown', e => {
    // Check if key is escape or tab is pressed
    const isTabPressed = e.key === TAB_CODE || e.code === TAB_CODE
    const isEscPressed = e.key === ESC_CODE || e.code === ESC_CODE

    // if escape key pressed
    if (isEscPressed) {
      // use callback for escape key
      escKeyCb()
      return
    } else if (!isTabPressed) {
      return
    }

    // if tab key is pressed
    if (e.shiftKey) {
      // when shift is also pressed
      if (document.activeElement === firstFocusableElement) {
        // add focus for the last focusable element
        lastFocusableElement.focus()
        e.preventDefault()
      }
    } else if (document.activeElement === lastFocusableElement) {
      // if focused has reached to last focusable element
      // then trigger focus on first focusable element
      firstFocusableElement.focus()
      e.preventDefault()
    }
  })
}
