import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Cta as CreditasCta } from 'components/Cta'

export const Cta = styled(CreditasCta)`
  text-decoration: none;
  transition: box-shadow 0.3s ease-in-out;
  box-shadow: ${selectTheme('shadow.xs')};
  white-space: nowrap;
  width: 100%;
  margin-bottom: ${selectTheme('spacingBottom.16xl')};

  ${media.up('3xl')} {
    width: 85%;
  }

  ${media.up('5xl')} {
    margin-bottom: 0;
    width: 288px;
  }
`
