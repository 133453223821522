import React from 'react'
import { func, object, string } from 'prop-types'

import { ButtonPrimaryTextOnly } from '@creditas-ui/button'
import { useTriggerEvents } from 'hooks/useTriggerEvents'

import { Cta } from './styles'

const propTypes = {
  buttonCta: object,
  label: string,
  onClick: func,
  productName: string,
}

const defaultProps = {
  buttonCta: {},
  onClick: () => {},
}

const ButtonCta = ({ buttonCta, label, onClick, ...others }) => {
  const { triggerEvent } = useTriggerEvents()

  if (!buttonCta || !label) return null

  const analyticEvent = {
    label: `${label}`,
    pageInteraction: buttonCta?.type !== 'link',
  }

  const handleOnClick = () => {
    triggerEvent(analyticEvent)
    onClick()
  }

  return (
    <Cta
      element={ButtonPrimaryTextOnly}
      width="flexible"
      size="large"
      onClick={handleOnClick}
      isButtonElement
      {...buttonCta}
      {...others}
    >
      {label}
    </Cta>
  )
}

ButtonCta.propTypes = propTypes
ButtonCta.defaultProps = defaultProps

export default ButtonCta
