import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

import { Container as CreditasContainer } from 'components/Container'

export const Section = styled.section``

const gridStyle = {
  default: css`
    grid-template-areas:
      'info'
      'image';
  `,
  reverse: css`
    grid-template-areas:
      'image'
      'info';
  `,
}

export const Grid = styled.div(
  ({ theme, verticalAlignment }) => css`
    width: 100%;
    height: 100%;

    display: grid;
    grid-gap: 0;
    grid-template-columns: 1fr;
    grid-template-rows: max-content;
    ${gridStyle[verticalAlignment === 'top' ? 'reverse' : 'default']}

    ${media.up('3xl')} {
      grid-gap: ${selectTheme('spacingX.9xl')({ theme })};
      grid-template-columns: repeat(8, 1fr);
      grid-template-areas: '${'image '.repeat(4).trim()} ${'info '
        .repeat(4)
        .trim()}';
    }

    ${media.up('5xl')} {
      grid-gap: ${selectTheme('spacingX.6xl')({ theme })};
      grid-template-columns: repeat(12, 1fr);
      grid-template-areas: '${'image '.repeat(6).trim()} . ${'info '
        .repeat(5)
        .trim()}';
    }
  `,
)

const containerStyle = {
  noPaddingOnMobile: css`
    ${media.down('2xl')} {
      padding-right: 0;
      padding-left: 0;
    }
  `,
}

export const Container = styled(CreditasContainer)(
  ({ noPaddingOnMobile, theme }) => css`
    padding-top: ${selectTheme('spacingTop.6xl')({ theme })};
    padding-bottom: ${selectTheme('spacingBottom.sm')({ theme })};

    ${media.up('3xl')} {
      padding-top: ${selectTheme('spacingTop.12xl')({ theme })};
      padding-bottom: ${selectTheme('spacingX.21xl')({ theme })};
    }

    ${noPaddingOnMobile ? containerStyle.noPaddingOnMobile : ''}
  `,
)
