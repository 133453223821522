import { bool, func, node } from 'prop-types'

import { AnimatePresence } from 'framer-motion'
import { useResizeHandler } from 'hooks/useResizeHandler'

import Body from './components/Body'
import CloseButton from './components/CloseButton'
import Container from './components/Container'

const propTypes = {
  children: node,
  onClose: func,
  visible: bool,
}

const Modal = ({ children, onClose, visible, ...others }) => {
  const { isMobile } = useResizeHandler('3xl')
  return (
    <AnimatePresence initial={false} exitBeforeEnter>
      {visible && (
        <Container isMobile={isMobile} handleClose={onClose} {...others}>
          <CloseButton onClick={onClose} />
          <Body>{children}</Body>
        </Container>
      )}
    </AnimatePresence>
  )
}

Modal.propTypes = propTypes

export default Modal
