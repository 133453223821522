import { css, styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const containerStyles = ({ theme }) => ({
  paddingOnMobile: css`
    ${media.down('5xl')} {
      padding-left: ${selectTheme('spacingRight.3xl')({ theme })};
      padding-right: ${selectTheme('spacingRight.3xl')({ theme })};
    }
  `,
})

export const Container = styled.div(
  ({ paddingOnMobile, theme }) => css`
    grid-area: info;
    width: 100%;

    ${media.up('4xl')} {
      padding-top: ${selectTheme('spacingRight.12xl')({ theme })};
    }

    ${containerStyles({ theme })[paddingOnMobile ? 'paddingOnMobile' : '']}
  `,
)
