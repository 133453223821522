import { string } from 'prop-types'

import { Container, Description, Title } from './styles'

const propTypes = {
  description: string,
  title: string,
}

const Card = ({ description, title }) => {
  if (!description && !title) return null
  return (
    <Container>
      <Title
        variant={{ _: 'bodyXlLight', '5xl': 'headingSmLight' }}
        color="neutral.90"
        children={title}
      />
      <Description
        variant={{ _: 'bodyXsRegular', '5xl': 'bodySmRegular' }}
        as="span"
        color="neutral.80"
        children={description}
      />
    </Container>
  )
}

Card.propTypes = propTypes
export default Card
