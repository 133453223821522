import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'
import { motion } from 'framer-motion'

import { animationsTiming } from '../../base.styles'

export const Container = styled(motion.div)`
  display: flex;
  flex-direction: column;
  padding: 24px ${selectTheme('spacingY.6xl')} 32px;
  max-height: 100vh;
  background-color: white;
  border-radius: 24px 24px 0 0;

  ${media.up('3xl')} {
    flex: 0.5;
    padding: ${selectTheme('spacingY.8xl')} ${selectTheme('spacingY.6xl')}
      ${selectTheme('spacingY.8xl')} 0;

    border-radius: 0;
  }
`

const mobileVariants = {
  hidden: {
    y: '488px',
  },
  visible: {
    y: '0',
    transition: {
      duration: animationsTiming.duration,
      type: 'spring',
      damping: 25,
      stiffness: 200,
    },
  },
  exit: {
    y: '90vh',
    transition: {
      ...animationsTiming,
      type: 'spring',
      damping: 25,
      stiffness: 200,
    },
  },
}

const desktopVariants = {
  hidden: {
    x: '100vw',
  },
  visible: {
    x: '0',
    transition: {
      ...animationsTiming,
      ease: 'easeInOut',
      damping: 0,
      stiffness: 0,
    },
  },
  exit: {
    x: '90vw',
    transition: {
      ...animationsTiming,
      ease: 'easeInOut',
      damping: 0,
      stiffness: 0,
    },
  },
}

const responsiveVariants = {
  mobile: mobileVariants,
  desktop: desktopVariants,
}

export const animationVariants = {
  hidden: isMobile =>
    responsiveVariants[isMobile ? 'mobile' : 'desktop'].hidden,
  visible: isMobile =>
    responsiveVariants[isMobile ? 'mobile' : 'desktop'].visible,
  exit: isMobile => responsiveVariants[isMobile ? 'mobile' : 'desktop'].exit,
}
