import { bool, node, object, oneOf, string } from 'prop-types'

import { Container, Grid, Section } from './styles'

const propTypes = {
  bgColor: string,
  children: node,
  currentSectionRef: object,
  noPaddingOnMobile: bool,
  verticalAlignment: oneOf(['top', 'bottom']),
}

const defaultProps = {
  bgColor: 'neutral.0',
  noPaddingOnMobile: false,
  verticalAlignment: 'top',
}

const Wrapper = ({
  children,
  currentSectionRef,
  noPaddingOnMobile,
  verticalAlignment,
  ...others
}) => {
  return (
    <Section {...others} ref={currentSectionRef}>
      <Container noPaddingOnMobile={noPaddingOnMobile}>
        <Grid verticalAlignment={verticalAlignment}>{children}</Grid>
      </Container>
    </Section>
  )
}

Wrapper.propTypes = propTypes
Wrapper.defaultProps = defaultProps

export default Wrapper
