import { styled } from '@creditas-ui/system'
import { media, selectTheme } from '@creditas-ui/utilities'

const Body = styled.div`
  overflow: hidden;
  overflow-y: auto;
  margin-top: 54px;
  margin-left: -32px;
  margin-right: -32px;
  padding: 8px 32px 32px;

  ${media.up('3xl')} {
    padding-left: ${selectTheme('spacingY.16xl')};
    padding-right: ${selectTheme('spacingY.16xl')};
  }

  ${media.up('4xl')} {
    padding-left: ${selectTheme('spacingY.19xl')};
    padding-right: ${selectTheme('spacingY.19xl')};
  }

  ${media.up('5xl')} {
    padding-left: ${selectTheme('spacingY.21xl')};
    padding-right: ${selectTheme('spacingY.21xl')};
  }
`

export default Body
