import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'
import { motion } from 'framer-motion'

import { animationsTiming } from '../../base.styles'

export const Container = styled(motion.div)`
  z-index: 99999999999;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #000000e1;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;

  ${media.up('3xl')} {
    flex-direction: row;
  }
`

export const animationVariants = {
  hidden: {
    opacity: 0,
  },
  visible: {
    opacity: 1,
    transition: {
      ...animationsTiming,
      ease: 'easeInOut',
    },
  },
  exit: {
    opacity: 0,
    transition: {
      ...animationsTiming,
      ease: 'easeInOut',
    },
  },
}
