import { styled } from '@creditas-ui/system'
import { selectTheme } from '@creditas-ui/utilities'

import RichText from 'components/RichText'

export const Typography = styled(RichText)`
  grid-area: title;

  margin-bottom: ${selectTheme('spacingBottom.6xl')};
`
