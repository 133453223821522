import { bool, node } from 'prop-types'

import { Container } from './styles'

const propTypes = {
  children: node,
  paddingOnMobile: bool,
}

const defaultProps = {
  paddingOnMobile: false,
}

const InfoContainer = ({ children, paddingOnMobile, ...others }) => {
  return (
    <Container paddingOnMobile={paddingOnMobile} {...others}>
      {children}
    </Container>
  )
}

InfoContainer.propTypes = propTypes
InfoContainer.defaultProps = defaultProps

export default InfoContainer
