import { func } from 'prop-types'

import closeIcon from '../assets/close-icon.svg'

import { Button, Icon } from './styles'

const propTypes = {
  onClick: func,
}

const CloseButton = ({ onClick }) => {
  return (
    <Button onClick={onClick}>
      <Icon src={closeIcon} />
    </Button>
  )
}

CloseButton.propTypes = propTypes
export default CloseButton
