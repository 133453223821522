import { styled } from '@creditas-ui/system'
import { media } from '@creditas-ui/utilities'

export const Button = styled.button`
  align-items: center;
  align-self: flex-end;
  appearance: none;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 24px;
  width: 24px;

  ${media.up('3xl')} {
    height: 32px;
    width: 32px;
    right: 32px;
  }
`

export const Icon = styled.img`
  display: block;
  flex: 1;
  height: 100%;
  object-fit: cover;
`
